<template>
  <v-dialog v-model="open" max-width="550">
    <template v-slot:activator="{ on, attrs }">
      <v-btn small :color="color" :disabled="disabled" :loading="loading" block outlined v-bind="attrs" v-on="on">{{
        $t('common.delete')
      }}</v-btn>
    </template>
    <v-card class="py-8">
      <v-row no-gutters justify="center">
        <v-col cols="auto">
          <v-icon size="100">{{ mdiAlertCircleOutline }}</v-icon>
        </v-col>
      </v-row>
      <v-card-title v-if="type" class="text-center justify-center text-h5 px-5" style="word-break: break-word">
        <i18n tag="div" path="dialog.delete_title">
          <template v-slot:type>
            <b>{{ type.toLowerCase() }}</b>
          </template>
          <template v-slot:label>
            <span>{{ identifier_label.toLowerCase() }}</span>
          </template>
          <template v-slot:value>
            <b>{{ identifier_value }}</b>
          </template>
        </i18n>
      </v-card-title>
      <v-card-title v-else class="text-center justify-center text-h5 px-5" style="word-break: break-word">
        {{ $t('dialog.delete_title_empty') }}
      </v-card-title>
      <v-row class="pa-0 ma-0" justify="center">
        <v-col cols="auto">
          <v-btn @click="deleteOnClick" class="error">{{ $t('common.delete') }}</v-btn>
        </v-col>
        <v-col cols="auto">
          <v-btn @click="open = false">{{ $t('common.cancel') }}</v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiAlertCircleOutline } from '@mdi/js'

export default {
  name: 'DeleteButton',
  props: {
    deleteOnClick: {
      type: Function,
      required: true,
    },
    type: {
      type: String,
    },
    identifier_label: {
      type: String,
    },
    identifier_value: {
      type: String,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    color: {
      type: String,
      required: false,
      default: 'error',
    },
  },

  data: () => {
    return {
      open: false,
      mdiAlertCircleOutline: mdiAlertCircleOutline,
    }
  },
}
</script>