<template>
  <v-form @submit.prevent>
    <v-row>
      <v-col cols="12" md="9">
        <v-card>
          <v-card-title>{{ $t('routes.users.edit') }}</v-card-title>
          <v-divider />

          <div class="pa-5">
            <v-row>
              <v-col md="4" cols="12">
                <v-text-field
                  v-model="firstname.model"
                  :error-messages="$t(firstname.error, { field: $t('label.firstname') })"
                  :label="$t('label.firstname')"
                  hide-details="auto"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col md="4" cols="12">
                <v-text-field
                  v-model="middlename.model"
                  :label="$t('label.middlename')"
                  outlined
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <v-col md="4" cols="12">
                <v-text-field
                  v-model="lastname.model"
                  :error-messages="$t(lastname.error, { field: $t('label.lastname') })"
                  :label="$t('label.lastname')"
                  hide-details="auto"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="email.model"
                  :error-messages="$t(email.error, { field: $t('label.email') })"
                  :label="$t('label.email')"
                  hide-details="auto"
                  type="email"
                  outlined
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-text-field
                  v-model="source"
                  :label="$t('label.source')"
                  hide-details="auto"
                  type="email"
                  outlined
                  disabled
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-select
                  v-model="roles.model"
                  :items="role_items"
                  :item-text="item => item.translations[$i18n.locale].name"
                  item-value="id"
                  :error-messages="$t(roles.error, { field: $t('label.role') })"
                  hide-details="auto"
                  chips
                  :label="$t('label.click_to_add_item', { item: $t('entity.role') })"
                  multiple
                  return-object
                />
              </v-col>
              <v-col cols="12">
                <v-avatar size="100px" color="primary" class="v-avatar-light-bg primary--text">
                  <v-img :src="avatar"></v-img>
                </v-avatar>
              </v-col>
            </v-row>
          </div>

          <v-row no-gutters>
            <v-col cols="12" md="6" lg="6">
              <v-card-text>
                <div class="text--secondary caption">
                  {{ $t('label.updated_at') }} {{ updated_at }}
                  <template v-if="updated_by"> {{ $t('label.by') }} {{ updated_by }} </template>
                </div>

                <div class="text--secondary caption">
                  {{ $t('label.created_at') }} {{ created_at }}
                  <template v-if="created_by"> {{ $t('label.by') }} {{ created_by }} </template>
                </div>
              </v-card-text>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="12" md="3">
        <v-card>
          <v-card-text>
            <v-btn
              :disabled="buttons_disabled"
              :loading="loading_edit_user"
              color="primary"
              type="submit"
              block
              small
              @click="editUser"
              class="mb-3"
            >
              <v-icon class="mr-1">{{ mdiContentSaveOutline }}</v-icon>
              {{ $t('common.save') }}
            </v-btn>
            <v-btn small block outlined @click="$router.push('/users')">
              <v-icon class="mr-1" small>{{ mdiArrowULeftTop }}</v-icon>
              {{ $t('common.back') }}
            </v-btn>
          </v-card-text>
          <v-divider class="my-1"></v-divider>
          <v-card-text>
            <v-btn
              v-if="!user_editing_himself && hasLaravelRoute('users.personify')"
              small
              block
              outlined
              color="primary"
              @click="personifyOnClick"
              :loading="loading_personify"
              :disabled="buttons_disabled"
              class="mb-3"
              >{{ $t('common.personify') }}
            </v-btn>
            <v-btn
              v-if="hasLaravelRoute('users.reset_password_email')"
              small
              block
              outlined
              @click="sendPasswordReset"
              :loading="loading_password_reset"
              :disabled="buttons_disabled"
              class="mb-3"
              >{{ $t('page.profile.reset_password') }}
            </v-btn>
            <v-btn
              v-if="hasLaravelRoute('2fa.reset')"
              small
              block
              outlined
              @click="resetTwoFactor"
              :loading="loading_2fa_reset"
              :disabled="buttons_disabled"
              class="mb-3"
              >{{ $t('page.profile.reset_two_factor') }}
            </v-btn>
            <delete-button
              v-if="!user_editing_himself"
              small
              :deleteOnClick="deleteUser"
              :loading="loading_delete_user"
              :disabled="buttons_disabled"
              :type="$t('entity.user')"
              :identifier_label="$t('label.firstname')"
              :identifier_value="firstname.model"
            />
          </v-card-text>
        </v-card>
      </v-col>

      <alert-dialog
        v-model="user_editing_himself_dialog"
        title="page.user.editing_yourself"
        description="page.user.editing_yourself_description"
      />
    </v-row>
  </v-form>
</template>

<script>
import Vue from 'vue'
import AlertDialog from '@/components/AlertDialog.vue'
import DeleteButton from '@/components/DeleteButton.vue'
import { mdiArrowULeftTop, mdiContentSaveOutline } from '@mdi/js'

export default {
  name: 'UserEdit',
  components: {
    AlertDialog,
    DeleteButton,
  },
  beforeCreate() {
    //TODO use users/id

    this.$http('roles/assignable/index').then(res => {
      this.available_roles = res.data

      this.$http('users/' + this.$route.params.id).then(res => {
        const user = res.data
        if (user) {
          this.firstname.model = user.firstname
          this.middlename.model = user.middlename
          this.lastname.model = user.lastname
          this.email.model = user.email
          this.roles.model = this.formatRoles(user.roles)
          this.avatar = user.avatar
          this.source = this.$t('label.sources.' + user.source)

          this.updated_at = Vue.filter('date')(user.updated_at)
          this.created_at = Vue.filter('date')(user.created_at)

          if (user.created_by) {
            this.created_by =
              user.created_by.firstname + ' ' + (user.created_by.middlename || '') + ' ' + user.created_by.lastname
          }
          if (user.updated_by) {
            this.updated_by =
              user.updated_by.firstname + ' ' + (user.updated_by.middlename || '') + ' ' + user.updated_by.lastname
          }
          this.id = user.id
        } else {
          this.$router.push('/users')
        }
      })
    })
  },
  mounted() {
    if (this.$store.state.user.id == this.$route.params.id) {
      this.user_editing_himself_dialog = true
      this.user_editing_himself = true
    }
  },
  data() {
    return {
      buttons_disabled: false,
      loading_edit_user: false,
      loading_delete_user: false,
      loading_password_reset: false,
      loading_2fa_reset: false,
      loading_personify: false,

      user_editing_himself_dialog: false,
      user_editing_himself: false,

      firstname: {
        model: '',
        error: '',
      },
      middlename: {
        model: '',
      },
      lastname: {
        model: '',
        error: '',
      },
      email: {
        model: '',
        error: '',
      },

      roles: {
        model: [],
        error: '',
      },

      avatar: '',

      available_roles: [],

      updated_at: '',
      created_at: '',
      updated_by: '',
      created_by: '',
      id: null,
      has2FA: false,

      mdiArrowULeftTop,
      mdiContentSaveOutline,
    }
  },
  computed: {
    role_items() {
      let result = JSON.parse(JSON.stringify(this.available_roles))
      if (this.roles.model) {
        this.roles.model.forEach(item => {
          result.push(item)
        })
      }
      return result
    },
  },
  methods: {
    editUser() {
      this.buttons_disabled = true
      this.loading_edit_user = true
      this.$http
        .post('users/' + this.$route.params.id, {
          email: this.email.model,
          firstname: this.firstname.model,
          middlename: this.middlename.model,
          lastname: this.lastname.model,
          roles: this.rolesResult(),
        })
        .then(res => {
          //Save yourself to the store
          if (this.$store.state.user.id == this.$route.params.id) {
            this.$store.commit('user/user', res.data)
          }
          this.$store.commit('notification/notify', {
            text: 'notification.saved',
          })

          //clear all errors
          this.firstname.error = ''
          this.middlename.error = ''
          this.lastname.error = ''
          this.email.error = ''
          this.roles.error = ''

          const user = res.data
          this.updated_at = Vue.filter('date')(user.updated_at)
          this.created_at = Vue.filter('date')(user.created_at)
        })
        .catch(error => {
          const errors = error.response.data.error
          this.email.error = errors.email ? errors.email[0] : ''
          this.firstname.error = errors.firstname ? errors.firstname[0] : ''
          this.lastname.error = errors.lastname ? errors.lastname[0] : ''
          this.roles.error = errors.roles ? errors.roles[0] : ''
        })
        .finally(() => {
          this.buttons_disabled = false
          this.loading_edit_user = false
          document.activeElement.blur()
        })
    },
    sendPasswordReset() {
      this.buttons_disabled = true
      this.loading_password_reset = true
      this.$http
        .post('reset_password_email', { email: this.email.model })
        .then(() => {
          this.$store.commit('notification/notify', {
            text: 'notification.reset_email_send',
            color: 'success',
          })
        })
        .finally(() => {
          this.buttons_disabled = false
          this.loading_password_reset = false
        })
    },
    resetTwoFactor() {
      this.buttons_disabled = true
      this.loading_2fa_reset = true
      this.$http
        .post('2fa/reset' + '?id=' + this.id)
        .then(() => {
          this.$store.commit('notification/notify', {
            text: 'notification.2fa_reset_success',
            color: 'success',
          })
        })
        .finally(() => {
          this.buttons_disabled = false
          this.loading_2fa_reset = false
        })
    },
    deleteUser() {
      this.buttons_disabled = true
      this.loading_delete_user = true
      this.$http
        .delete('users/' + this.id)
        .then(() => {
          this.$store.commit('notification/notify', {
            text: 'notification.deleted_success',
            color: 'success',
          })
          this.$router.push('/users')
        })
        .finally(() => {
          this.buttons_disabled = false
          this.loading_delete_user = false
        })
    },
    personifyOnClick() {
      this.buttons_disabled = true
      this.loading_delete_user = true

      this.$http
        .post('users/personify/' + this.id)
        .then(res => {
          if (res.status == 200) {
            this.$store.commit('user/personified', true)
            this.$store.commit('user/user', res.data.user)
            this.$store.commit('notification/notify', {
              text: 'notification.personification_success',
              color: 'success',
            })
            this.$router.push('/profile')
          }
        })
        .finally(() => {
          this.buttons_disabled = false
          this.loading_delete_user = false
        })
    },
    rolesResult() {
      let result = []
      this.roles.model.forEach(role => {
        result.push(role.id)
      })
      return result
    },
    formatRoles(roles) {
      roles.forEach(role => {
        role.disabled = !this.isAssignableByCurrentRole(role)
      })

      return roles
    },
    isAssignableByCurrentRole(role) {
      let result = false
      this.available_roles.forEach(available_role => {
        if (available_role.id == role.id) {
          result = true
        }
      })
      return result
    },
  },
}
</script>
